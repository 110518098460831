import * as React from "react"
import ProfilePic from "../assets/image/demo.jpg"
import Notes from "../assets/image/notes.svg"
import Layout from "../components/layout"
import moment from "moment"
import ReactMarkdown from "react-markdown"
import ContinueReading from "./Continue-Reading"
const Profile = props => {
  const {
    blogData: {
      tag,
      title,
      thumbnail,
      author,
      blog_category: category,
      content,
      created_at,
    },
    key,
    lang,
  } = props.pageContext

  return (
    <Layout>
      <div className="blog py-40 pb-16 content mx-auto px-10 lg:px-0">
        <div className="text-center">
          <p className="text-md font-semibold">{category.name}</p>
          <p className="text-3xl font-semibold blue-dark2 my-5 line-heading">
            {title}
          </p>
          <p>{author.name}</p>
          <p className="my-5">{moment(created_at).format("MMM DD Y")}</p>
        </div>
        <div className="relative my-20">
          <div className="absolute -top-12 left-1/2 h-20 w-20 sm:h-24 sm:w-24 bg-white p-1 rounded-full transform -translate-x-1/2">
            <img
              src={author.thumbnail?.url || ProfilePic}
              className="rounded-full h-full w-full"
            />
          </div>
          {thumbnail && (
            <div className="rounded-3xl shadow-even border-8 border-white blog-post-height flex items-center justify-center overflow-hidden">
              <img
                src={thumbnail.url}
                className="rounded-xl min-h-full min-w-full"
              />
            </div>
          )}
        </div>

        <div className="main-blog-content text-sm">
          {content.map(p => {
            if (p.paragraph_type === "image_left") p.dir = -1
            else p.dir = 1

            return p.paragraph_type === "quote" ? (
              <QuoteParagraph {...p} />
            ) : p.paragraph_type === "plain" ? (
              <PlainParagraph {...p} />
            ) : (
              <ImgParagraph {...p} />
            )
          })}
        </div>

        {/* <div className="xl:px-24 mt-24">
          <div className="mt-5 sm:pr-6 md:pr-0">
            <ContinueReading tag={tag.split(",")} title={title} />
          </div>
        </div> */}
      </div>
    </Layout>
  )
}

const QuoteParagraph = props => {
  return (
    <div className="my-12 flex flex-col lg:flex-row items-start text sm:px-5 lg:px-40 line-text text-justify lg:text-left text-justify lg:text-left">
      <div className="lg:pr-16 w-full lg:w-2/3">
        <ReactMarkdown>{props.content}</ReactMarkdown>
      </div>
      <div className="border-l pl-10 mt-12 lg:mt-0 w-full lg:w-1/3">
        <div className="mb-6 lg:mb-12">
          <img src={Notes} />
        </div>
        <p className="text-xl italic text line-text">
          <ReactMarkdown>{props.quote}</ReactMarkdown>
        </p>
      </div>
    </div>
  )
}

const ImgParagraph = props => {
  return (
    <div className="flex flex flex-col lg:flex-row items-start text sm:px-6 lg:px-48 text-justify sm:text-left">
      {[
        {
          e: (
            <div className="lg:pl-8 w-full lg:w-2/3 mt-3 lg:mt-0">
              <ReactMarkdown>{props.content}</ReactMarkdown>
            </div>
          ),
          f: 1,
        },
        {
          e: (
            <div className="text text-lg w-5/6 sm:w-1/2 lg:w-1/3 mx-auto max-w-max">
              <img src={props.image.url} className="rounded-2xl" />
              <ReactMarkdown>{props.alt}</ReactMarkdown>
            </div>
          ),
          f: 2,
        },
      ]
        .sort((a, b) => props.dir * (a.f - b.f))
        .map(({ e }) => e)}
    </div>
  )
}

const PlainParagraph = props => {
  return (
    <div className="my-12 text sm:px-6 lg:px-48 text-justify sm:text-left">
      <ReactMarkdown>{props.content}</ReactMarkdown>
    </div>
  )
}

export default Profile
